import Button from 'antd/es/button';
import Search from 'antd/es/input/Search';
import Table from 'antd/es/table';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { PrintedComponent } from '../../../components/PrintedComponent';
import { formatHumanDateTime } from '../../../utils/date';
import { StepOneFlagFilter } from '../FlagsCell/FlagsCellStepOne/flags';
import { PrintedSpecimenTable } from '../PrintedSpecimenTable';
import { SpecimentIdColumntTitle } from '../SpecimentIdColumntTitle';
import { LabSessionProps, StepOneTableData, TableStepOneProps } from '../types';
import { getStepOneColumns, getStepOnePrintedColumns } from './columns';
import { useMoveStepTwo, useStepOne, useStepOneSearch } from './hooks';
import s from './TableStepOne.module.scss';

export const SESSION_PAGE_SIZE = 20;

export function TableStepOneContainer(props: LabSessionProps) {
    const { labSession, sessionReload } = props;
    const { stepOneTableRD, stepOneDataReload } = useStepOne(props);
    return (
        <RenderRemoteData remoteData={stepOneTableRD}>
            {(stepOneTableData) => {
                return (
                    <TableStepOne
                        stepOneTableData={stepOneTableData}
                        stepOneDataReload={stepOneDataReload}
                        labSession={labSession}
                        sessionReload={sessionReload}
                    />
                );
            }}
        </RenderRemoteData>
    );
}

export function TableStepOne(props: TableStepOneProps) {
    const { stepOneTableData, stepOneDataReload, labSession, sessionReload } = props;
    const { filteredTableData, flagFilters, setSearchText, setFlagFilters } =
        useStepOneSearch(props);

    const { handleChangeToStep2, erroredRows, removeRecordError } = useMoveStepTwo({
        sessionReload,
        setFlagFilters,
    });

    const editableTableColumns = getStepOneColumns(
        stepOneDataReload,
        flagFilters,
        removeRecordError,
        labSession.status,
    );

    return (
        <>
            <div className={s.actionsBlock}>
                <Search
                    placeholder={`Name, MRN, ${SpecimentIdColumntTitle()} or Note`}
                    title={`Search by Name, MRN, ${SpecimentIdColumntTitle()} or Note`}
                    enterButton="Search"
                    allowClear
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    onSearch={setSearchText}
                    className={s.searchInput}
                />
                <div className={s.actionButtons}>
                    <PrintedComponent printButtonTitle={'Print'}>
                        <PrintedSpecimenTable<StepOneTableData>
                            labSessionDate={formatHumanDateTime(labSession.createdDateTime)}
                            dataSource={stepOneTableData}
                            columns={getStepOnePrintedColumns()}
                        />
                    </PrintedComponent>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleChangeToStep2(labSession.id);
                        }}
                    >
                        Move to Step 2
                    </Button>
                </div>
            </div>
            <Table
                className={s.table}
                columns={editableTableColumns}
                dataSource={filteredTableData}
                bordered
                rowKey={(record) => record.id}
                onChange={(_pagination, filters) => {
                    const flagFilters = (filters.flag ? filters.flag : []) as StepOneFlagFilter[];
                    setFlagFilters(flagFilters);
                }}
                pagination={{
                    pageSize: SESSION_PAGE_SIZE,
                    showSizeChanger: false,
                }}
                rowClassName={(record) => (erroredRows.has(record.id) ? s.errored : '')}
            />
        </>
    );
}
