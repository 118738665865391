import { Button, Radio, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableLoading } from 'components/TableLoading';
import { differenceInMinutes, formatDistance, formatDuration, intervalToDuration } from 'date-fns';
import { Link } from 'react-router-dom';
import { formatHumanDateTime } from 'utils/date';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { BaseLayout } from '../../components/BaseLayout';
import { OrderItem, useLabOrders } from './hooks';
import s from './OrderList.module.scss';

const filterOptions = [
    { label: 'Last 24 hours', value: true, title: 'Show survey data for the last 24 hours' },
    { label: 'All data', value: false, title: 'Show survey data for all time' },
];

const orderOptions = [
    {
        label: 'Most recent',
        value: 'default',
        title: 'Show most recent survey data first',
    },
    {
        label: 'Ordering Provider',
        value: 'ordering-provider',
        title: 'Sort by Ordering Provider column',
    },
];

export function OrderList() {
    const {
        ordersRd,
        pageNumber,
        pageSize,
        setPageNumber,
        setPageSize,
        totalOrders,
        setFilter,
        filter,
        order,
        setOrder,
    } = useLabOrders();

    return (
        <BaseLayout showHeader={true} showMainMenu={true} className={s.wrapper}>
            <div className={s.header}>
                <h2>Surveys</h2>
                <div className={s.filtersGroup}>
                    <div className={s.filerItem}>
                        <h4>Order by:</h4>
                        <Radio.Group
                            options={orderOptions}
                            onChange={(e) => setOrder(e.target.value)}
                            value={order}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                    <div className={s.filerItem}>
                        <h4>Filter:</h4>
                        <Radio.Group
                            options={filterOptions}
                            onChange={(e) => setFilter(e.target.value)}
                            value={filter}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                </div>
            </div>
            <RenderRemoteData
                remoteData={ordersRd}
                renderLoading={() => (
                    <TableLoading
                        pageNumber={pageNumber}
                        pageSize={pageSize}
                        total={totalOrders}
                        baseColumns={getOrdersColumns()}
                    />
                )}
            >
                {({ orderItems, total }) => {
                    const paginationConfig = {
                        current: pageNumber,
                        pageSize: pageSize,
                        total: total,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                    };
                    return (
                        <Table
                            className={s.table}
                            columns={getOrdersColumns()}
                            dataSource={orderItems}
                            bordered
                            size="middle"
                            pagination={{
                                onChange: (newPage, newPageSize) => {
                                    setPageNumber(newPage);
                                    setPageSize(newPageSize);
                                },
                                ...paginationConfig,
                            }}
                            rowKey={(record) => record.observation}
                        />
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function getOrdersColumns(): ColumnsType<OrderItem> {
    const columns: ColumnsType<OrderItem> = [
        {
            title: 'MRN',
            dataIndex: 'mrn',
            key: 'mrn',
        },
        {
            title: 'Ordering Provider',
            dataIndex: 'requester',
            key: 'requester',
        },
        {
            title: 'Ordered',
            dataIndex: 'order_date_time',
            key: 'order_date_time',
            render: (order_date_time) => {
                return (
                    <>
                        {formatHumanDateTime(order_date_time)} -{' '}
                        {formatDistance(new Date(order_date_time), new Date())} ago
                    </>
                );
            },
        },
        {
            title: 'Response collected',
            dataIndex: 'questionnaire_response_date_time',
            key: 'questionnaire_response_date_time',
            render: (questionnaire_response_date_time, record) => {
                if (!questionnaire_response_date_time) {
                    return '-';
                }
                const interval = intervalToDuration({
                    start: new Date(record.order_date_time),
                    end: new Date(questionnaire_response_date_time),
                });
                const resultDifferenceInMinutes = differenceInMinutes(
                    new Date(questionnaire_response_date_time),
                    new Date(record.order_date_time),
                );
                if (resultDifferenceInMinutes < 0) {
                    return (
                        <span style={{ color: 'red' }} title="Response collected before order">
                            {formatHumanDateTime(questionnaire_response_date_time)}
                        </span>
                    );
                }

                return (
                    <span
                        title={`in ${formatDuration(interval, {
                            format: ['years', 'months', 'days', 'hours', 'minutes'],
                        })}`}
                    >
                        {formatHumanDateTime(questionnaire_response_date_time)}
                    </span>
                );
            },
        },
        {
            title: 'Response',
            dataIndex: 'observation',
            key: 'observation',
            render: (observation, record) => {
                const { questionnaire_response_id } = record;
                if (!questionnaire_response_id) {
                    return (
                        <>
                            <Button type="link">
                                <Link to={`/surveys/${observation}/new/`}>Add</Link>
                            </Button>
                        </>
                    );
                }
                return (
                    <>
                        <Button type="link">
                            <Link to={`/surveys/${observation}/${questionnaire_response_id}`}>
                                View
                            </Link>
                        </Button>
                    </>
                );
            },
        },
    ];
    return columns;
}
