import { notification } from 'antd';
import { getUserRoles } from 'containers/UserList/utils';
import { createContext, useEffect } from 'react';
import { ApplicationConfig, sharedApplicationConfig } from 'sharedState';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { resetInstanceToken, setInstanceToken } from 'aidbox-react/lib/services/instance';
import { sequenceMap, service } from 'aidbox-react/lib/services/service';

import { User } from '../../contrib/aidbox';
import {
    getToken,
    getUserInfo,
    removeToken,
    serviceProvider,
    setSessionId,
} from '../../services/auth';
import { extractErrorCode } from '../../utils/error';

const token = getToken();

interface Context {
    user: User | null;
}

export const InitialDataContext = createContext<Context | null>(null);

export function useApp() {
    const [userRD] = useService(async () => {
        if (!token) {
            return success(null);
        }
        setInstanceToken(JSON.parse(token));
        serviceProvider.setInstanceToken(JSON.parse(token));
        const response = await getUserInfo();
        if (isFailure(response) && extractErrorCode(response.error) !== 'network_error') {
            resetInstanceToken();
            serviceProvider.resetInstanceToken();
            removeToken();
            localStorage.setItem('entry_point', window.location.pathname);
            window.location.reload();
            return success(null);
        }
        localStorage.removeItem('entry_point');

        if (isSuccess(response)) {
            if (getUserRoles(response.data).includes('unprivileged')) {
                notification.error({
                    description:
                        'You have no permissions to access this application. Please contact your administrator.',
                    message: 'Unprivileged user',
                    duration: 0,
                });
            }
        }

        return response;
    });

    const [userConfigRD] = useService(async () => {
        if (!token) {
            return success(null);
        }
        const response = await service<ApplicationConfig>({ url: '/$get-application-config' });
        if (isSuccess(response)) {
            sharedApplicationConfig.setSharedState(response.data);
        }

        return response;
    });

    useEffect(() => {
        setSessionId();
    }, []);

    const initialDataRD = sequenceMap({ user: userRD, applicationConfig: userConfigRD });

    return { initialDataRD };
}
