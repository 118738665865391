import {
    QuestionnaireResponseForm,
    questionnaireIdWOAssembleLoader,
} from '@beda.software/fhir-questionnaire';
import { QuestionnaireResponseFormSaveResponse } from '@beda.software/fhir-questionnaire/components/QuestionnaireResponseForm/questionnaire-response-form-data';
import { Questionnaire } from '@beda.software/fhir-questionnaire/contrib/aidbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { ParametersParameter, QuestionnaireResponse as FHIRQuestionnaireResponse } from 'fhir/r4b';
import { ReactElement } from 'react';
import { serviceProvider } from 'services/auth';

import { BaseForm } from '../form';
import { questionnaireToValidationSchema } from '../utils';
import { QuestionDateTime } from '../widgets/date';
import { InlineChoice } from '../widgets/inline-choice';
import { QuestionInteger, QuestionPercentage } from '../widgets/number';
import { QuestionString } from '../widgets/string';

export function BaseQuestionnaireResponseForm(props: {
    questionnaireId: string;
    questionnaire: Questionnaire;
    launchContextParameters?: ParametersParameter[];
    initialQuestionnaireResponse?: Partial<FHIRQuestionnaireResponse>;
    onSuccess?: (response: QuestionnaireResponseFormSaveResponse) => void;
    onFailure?: (response: Error) => void;
    remoteDataRenderConfig?: {
        renderFailure?: (error: any) => ReactElement;
        renderLoading?: () => ReactElement;
    };
    readOnly?: boolean;
    setReadOnly?: (readOnly: boolean) => void;
}) {
    return (
        <QuestionnaireResponseForm
            questionnaireLoader={questionnaireIdWOAssembleLoader(props.questionnaireId)}
            launchContextParameters={props.launchContextParameters}
            initialQuestionnaireResponse={props.initialQuestionnaireResponse}
            readOnly={props.readOnly}
            onSuccess={props.onSuccess}
            remoteDataRenderConfig={props.remoteDataRenderConfig}
            onFailure={props.onFailure}
            serviceProvider={serviceProvider}
            widgetsByQuestionType={{
                dateTime: QuestionDateTime,
                integer: QuestionInteger,
                choice: InlineChoice,
                string: QuestionString,
            }}
            widgetsByQuestionItemControl={{
                'percentage-slider': QuestionPercentage,
            }}
            validation={{
                mode: 'all',
                resolver: yupResolver(questionnaireToValidationSchema(props.questionnaire)),
            }}
            FormWrapper={({ items, handleSubmit }) => {
                return (
                    <BaseForm
                        handleSubmit={handleSubmit}
                        items={items}
                        readOnly={props.readOnly}
                        setReadOnly={props.setReadOnly}
                    />
                );
            }}
        />
    );
}
