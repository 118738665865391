import { QuestionItemProps } from '@beda.software/fhir-questionnaire';
import { Form, Input } from 'antd';

import { useFieldController } from '../hooks';

export function QuestionString({ parentPath, questionItem }: QuestionItemProps) {
    const { linkId } = questionItem;
    const fieldName = [...parentPath, linkId, 0, 'value', 'string'];
    const { value, onChange, disabled, formItem, onBlur } = useFieldController(
        fieldName,
        questionItem,
    );

    return (
        <Form.Item {...formItem}>
            <Input value={value} disabled={disabled} onChange={onChange} onBlur={onBlur} />
        </Form.Item>
    );
}
