import { Breadcrumb, Card, Skeleton, Space } from 'antd';
import { AlertFailure } from 'components/AlertFailure';
import { BaseQuestionnaireResponseForm } from 'components/BaseQuestionnaireResponseForm/QuestionnaireResponseForm';
import { useNavigate } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib';

import { BaseLayout } from '../../components/BaseLayout';
import { useSurvey } from './hooks';
import s from './SurveyForm.module.scss';

export function SurveyForm() {
    const { resourcesRD, readOnly, setReadOnly, onSuccess } = useSurvey();
    const navigate = useNavigate();
    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <a>Surveys</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Survey</Breadcrumb.Item>
            </Breadcrumb>
            <h2>Survey Response</h2>
            <RenderRemoteData
                remoteData={resourcesRD}
                renderLoading={() => (
                    <Card className={s.wrapper}>
                        <FormSkeleton />
                    </Card>
                )}
                renderFailure={(error) => <AlertFailure error={error} />}
            >
                {({ questionnaire, questionnaireResponse }) => {
                    return (
                        <Card className={s.wrapper}>
                            <BaseQuestionnaireResponseForm
                                questionnaireId="survey"
                                remoteDataRenderConfig={{
                                    renderLoading: () => <FormSkeleton />,
                                    renderFailure(error) {
                                        return <AlertFailure error={error} />;
                                    },
                                }}
                                questionnaire={questionnaire}
                                readOnly={readOnly}
                                onSuccess={onSuccess}
                                setReadOnly={setReadOnly}
                                initialQuestionnaireResponse={questionnaireResponse}
                            />
                        </Card>
                    );
                }}
            </RenderRemoteData>
        </BaseLayout>
    );
}

export function FormSkeleton() {
    const active = true;
    const size = 'small';
    const block = true;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
            }}
        >
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
            <Space direction="vertical">
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
                <Skeleton.Input active={active} size={size} block={block} />
            </Space>
        </div>
    );
}
