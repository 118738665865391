import { QuestionnaireResponseFormSaveResponse } from '@beda.software/fhir-questionnaire/components/QuestionnaireResponseForm/questionnaire-response-form-data';
import { Questionnaire } from '@beda.software/fhir-questionnaire/contrib/aidbox';
import { formatError, WithId } from 'aidbox-react';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Bundle,
    Encounter,
    Observation,
    Patient,
    RiskAssessment,
    ServiceRequest,
} from 'shared/src/contrib/aidbox';

import { service } from 'aidbox-react/lib';
import { useService } from 'aidbox-react/lib/hooks/service';
import {
    extractBundleResources,
    getFHIRResource,
    makeReference,
} from 'aidbox-react/lib/services/fhir';
import { mapSuccess, sequenceMap } from 'aidbox-react/lib/services/service';

export function useNewSurvey() {
    const { observation } = useParams();
    const navigate = useNavigate();

    const [resourcesRD, resourcesManager] = useService(async () => {
        const questionnaireRD = await getFHIRResource<Questionnaire>(
            makeReference('Questionnaire', 'survey'),
        );
        const resourcesRD = await service<
            Bundle<WithId<Observation | Patient | Encounter | RiskAssessment | ServiceRequest>>
        >({
            method: 'GET',
            url: `/Observation?_id=${observation}
            &_include:logical=patient,encounter:Encounter
            &_revinclude:logical=RiskAssessment:encounter:Encounter
            &_include:logical=RiskAssessment:basis:ServiceRequest
            &_include:logical=ServiceRequest:requester`,
        });

        const resourcesMap = sequenceMap({
            questionnaireRD,
            resourcesRD,
        });

        return mapSuccess(resourcesMap, ({ questionnaireRD, resourcesRD }) => {
            const resources = extractBundleResources(resourcesRD);

            return {
                observation: resources.Observation[0],
                patient: resources.Patient[0],
                serviceRequest: resources.ServiceRequest[0],
                questionnaire: questionnaireRD,
            };
        });
    });

    const onSuccess = async (response: QuestionnaireResponseFormSaveResponse) => {
        const qrId = response.questionnaireResponse.id!;

        notification.success({ message: 'Survey saved successfully!' });
        navigate(`/surveys/${observation}/${qrId}`, { replace: true });
    };

    const onFailure = (error: Error) => {
        notification.error({ message: formatError(error) });
        console.error('Failed to load resources', error);
    };

    return { resourcesRD, resourcesManager, onSuccess, onFailure };
}
