import { Questionnaire } from '@beda.software/fhir-questionnaire/contrib/aidbox';
import { notification } from 'antd';
import { QuestionnaireResponse } from 'fhir/r4b';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { serviceProvider } from 'services/auth';

import { sequenceMap } from 'aidbox-react/lib';
import { useService } from 'aidbox-react/lib/hooks/service';
import { getFHIRResource, makeReference } from 'aidbox-react/lib/services/fhir';
export function useSurvey() {
    const { qr_id } = useParams();
    const [readOnly, setReadOnly] = useState(true);

    const [resourcesRD, resourcesManager] = useService(async () => {
        const questionnaireResponse = await serviceProvider.getFHIRResource<QuestionnaireResponse>({
            reference: `QuestionnaireResponse/${qr_id}`,
        });

        const questionnaire = await getFHIRResource<Questionnaire>(
            makeReference('Questionnaire', 'survey'),
        );

        return sequenceMap({  questionnaireResponse,  questionnaire });
    });

    const onSuccess = async () => {
        notification.success({ message: 'Survey saved successfully!' });
        resourcesManager.softReloadAsync();
        setReadOnly(true);
    };

    return { resourcesRD, resourcesManager, readOnly, setReadOnly, onSuccess };
}
