import { useState } from 'react';
import { dateTime } from 'shared/src/contrib/aidbox';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure } from 'aidbox-react/lib/libs/remoteData';
import { mapSuccess, service } from 'aidbox-react/lib/services/service';

export interface OrdersWithTotal {
    orderItems: OrderItem[];
    total: number;
}

export interface OrderItem {
    sis_id: string;
    observation: string;
    mrn: string;
    requester: string;
    order_date_time: dateTime;
    questionnaire_response_id: string | null;
    questionnaire_response_date_time: dateTime | null;
}

export function useLabOrders() {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);
    const [filter, setFilter] = useState(true);
    const [order, setOrder] = useState('default');

    const [ordersRd, ordersManager] = useService(async () => {
        const response = await service<OrdersWithTotal>({
            method: 'GET',
            url: `/$orders-list?_count=${pageSize}&_page=${pageNumber}&last_24_hours=${filter}&order_by=${order}`,
        });
        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }
        return mapSuccess(response, ({ orderItems, total }) => {
            setTotalOrders(total);
            const data: OrdersWithTotal = {
                orderItems,
                total,
            };
            return data;
        });

        return response;
    }, [pageNumber, pageSize, filter, order]);

    return {
        ordersRd,
        manager: ordersManager.softReloadAsync,
        setPageNumber,
        pageNumber,
        setPageSize,
        pageSize,
        totalOrders,
        filter,
        setFilter,
        order,
        setOrder,
    };
}
