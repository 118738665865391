import Table, { ColumnsType } from 'antd/es/table';

import { ReactComponent as Logo } from '../../../images/Logo.svg';
import { StepOneTableData, StepTwoTableData } from '../types';
import { useUserDisplay } from './hooks';
import s from './PrintedSpecimenTable.module.scss';

export interface PrintedSpecimenTableProps<T> {
    labSessionDate: string;
    columns: ColumnsType<T>;
    dataSource: Array<T>;
}

export function PrintedSpecimenTable<T extends StepOneTableData | StepTwoTableData>(
    props: PrintedSpecimenTableProps<T>,
) {
    return (
        <table className={s.printTable}>
            <thead>
                <tr>
                    <th>
                        <PrintedSpecimenTableHeader {...props} />
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <Table
                            columns={props.columns}
                            dataSource={props.dataSource}
                            bordered
                            rowKey={(record) => record.specimen_identifier}
                            pagination={false}
                            size="small"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

function PrintedSpecimenTableHeader<T>(props: PrintedSpecimenTableProps<T>) {
    const userDisplay = useUserDisplay();
    return (
        <div className={s.header}>
            <Logo />
            <span>
                Session at: <b>{props.labSessionDate}</b>
            </span>
            <span>
                Printed by: <b>{userDisplay}</b>
            </span>
            <span />
        </div>
    );
}
