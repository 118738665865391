import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LabSession } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';

import { SetActions } from '../../../hooks/useSet';
import { AliquotsSelect } from '../AliquotsSelect';
import { FlagsCellStepTwo } from '../FlagsCell/FlagsCellStepTwo';
import { StepTwoFlagFilter, stepTwoFlagsFilters } from '../FlagsCell/FlagsCellStepTwo/flags';
import { NotesCell } from '../NotesCell';
import { SpecimentIdColumntTitle } from '../SpecimentIdColumntTitle';
import { StepTwoTableData, TableStepTwoProps } from '../types';
import s from './TableStepTwo.module.scss';
import { AliquotsStructure } from './types';

const stepTwoBaseColumns: ColumnsType<StepTwoTableData> = [
    {
        title: 'Patient name',
        key: 'patient_name',
        dataIndex: 'patient_name',
        className: s.nameColumnWrapper,
        render: (text) => {
            return (
                <Tooltip title={text} className={s.nameColumn}>
                    <div>{text}</div>
                </Tooltip>
            );
        },
    },
    {
        title: 'MRN',
        key: 'mrn',
        dataIndex: 'mrn',
        width: '100px',
        className: s.nameColumnWrapper,
        render: (text) => {
            return (
                <Tooltip title={text} className={s.nameColumn}>
                    <div>{text}</div>
                </Tooltip>
            );
        },
    },
    {
        title: SpecimentIdColumntTitle,
        key: 'specimen_identifier',
        dataIndex: 'specimen_identifier',
        className: s.specimenColumnWrapper,
        render: (text, record) => {
            return (
                <Tooltip title={text} className={s.specimenColumn}>
                    {text}
                </Tooltip>
            );
        },
    },
    {
        title: 'Collection date',
        key: 'specimen_collected',
        dataIndex: 'specimen_collected',
        className: s.collectedColumnWrapper,
        render: (text) => {
            return <div>{formatHumanDateTime(text)}</div>;
        },
    },

    {
        title: 'Sample Number',
        key: 'labSampleItem',
        dataIndex: 'labSampleItem',
        width: '50',
    },
    {
        title: 'Box set',
        key: 'boxset',
        dataIndex: 'boxset',
        width: '50px',
    },
];

export function getStepTwoColumns(
    stepTwoDataReload: TableStepTwoProps['stepTwoDataReload'],
    filterFlags: StepTwoFlagFilter[],
    aliquotsStructure: AliquotsStructure,
    removeRecordError: SetActions<string>['remove'],
    labSessionStatus: LabSession['status'],
    boxsetArrangementId: string,
    viewOnly?: boolean,
): ColumnsType<StepTwoTableData> {
    return [
        ...stepTwoBaseColumns,
        ...getAliquotsColumns(
            aliquotsStructure,
            stepTwoDataReload,
            removeRecordError,
            boxsetArrangementId,
            true,
            viewOnly,
        ),
        ...getEditableCellsForStepTwo(
            stepTwoDataReload,
            filterFlags,
            removeRecordError,
            labSessionStatus,
            '180px',
            viewOnly,
        ),
    ];
}

function getAliquotsColumns(
    aliquotsStructure: AliquotsStructure,
    stepTwoDataReload: TableStepTwoProps['stepTwoDataReload'],
    removeRecordError: SetActions<string>['remove'],
    boxsetArrangementId: string,
    showDefaultValue: boolean,
    viewOnly?: boolean,
): ColumnsType<StepTwoTableData> {
    return [
        {
            title: 'Aliquots',
            children: Object.entries(aliquotsStructure).map(([_key, aliquot]) => {
                return {
                    title: aliquot.tubeVolume,
                    dataIndex: aliquot.tubeType,
                    key: aliquot.tubeType,
                    width: '60px',
                    className: s.aliquots,
                    render: (_, record) => {
                        return showDefaultValue ? (
                            <AliquotsSelect
                                key={aliquot.tubeType}
                                aliquot={aliquot}
                                record={record}
                                reload={stepTwoDataReload}
                                removeRecordError={removeRecordError}
                                boxsetArrangementId={boxsetArrangementId}
                                viewOnly={viewOnly}
                            />
                        ) : null;
                    },
                };
            }),
        },
    ];
}

export function getStepTwoPrintedColumns(
    aliquotsStructure: AliquotsStructure,
    stepTwoDataReload: TableStepTwoProps['stepTwoDataReload'],
    removeRecordError: SetActions<string>['remove'],
    boxsetArrangementId: string,
): ColumnsType<StepTwoTableData> {
    return [
        {
            title: '#',
            key: 'number',
            dataIndex: 'number',
            width: 26,
            render: (_text: string, _record: any, index: number) => index + 1,
        },
        ...stepTwoBaseColumns,
        ...getAliquotsColumns(
            aliquotsStructure,
            stepTwoDataReload,
            removeRecordError,
            boxsetArrangementId,
            false,
        ),
        {
            title: 'Flags and Notes',
            key: 'flagsAndNotes',
            dataIndex: 'flagsAndNotes',
            width: '240px',
        },
    ];
}

export function getEditableCellsForStepTwo(
    stepTwoDataReload: TableStepTwoProps['stepTwoDataReload'],
    filterFlags: StepTwoFlagFilter[],
    removeRecordError: SetActions<string>['remove'],
    sessionStatus: LabSession['status'],
    width?: string,
    viewOnly?: boolean,
): ColumnsType<StepTwoTableData> {
    return [
        {
            title: 'Flags',
            key: 'flag',
            dataIndex: 'flag',
            filters: stepTwoFlagsFilters as any,
            width: width || '240px',
            filteredValue: filterFlags,
            render: (_, record) => (
                <FlagsCellStepTwo
                    record={record}
                    reload={stepTwoDataReload}
                    removeRecordError={removeRecordError}
                    sessionStatus={sessionStatus}
                    viewOnly={viewOnly}
                />
            ),
            className: s.flagCell,
        },
        {
            title: 'Notes',
            key: 'notes',
            dataIndex: 'notes',
            width: width || '260px',
            render: (_, record) => {
                return (
                    <NotesCell
                        record={record}
                        reload={stepTwoDataReload}
                        sessionStatus={sessionStatus}
                        viewOnly={viewOnly}
                    />
                );
            },
        },
    ];
}
