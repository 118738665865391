import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LabSession } from 'shared/src/contrib/aidbox';
import { formatHumanDateTime } from 'utils/date';

import { SetActions } from '../../../hooks/useSet';
import { FlagsCellStepOne } from '../FlagsCell/FlagsCellStepOne';
import { StepOneFlagFilter, stepOneFlagsFilters } from '../FlagsCell/FlagsCellStepOne/flags';
import { NotesCell } from '../NotesCell';
import { SpecimentIdColumntTitle } from '../SpecimentIdColumntTitle';
import { StepOneTableData, TableStepOneProps } from '../types';
import s from './TableStepOne.module.scss';

const stepOneBaseColumns: ColumnsType<StepOneTableData> = [
    {
        title: 'Patient name',
        key: 'patient_name',
        dataIndex: 'patient_name',
        className: s.nameColumnWrapper,
        render: (text) => {
            return (
                <Tooltip title={text} className={s.nameColumn}>
                    <div>{text}</div>
                </Tooltip>
            );
        },
    },
    {
        title: 'MRN',
        key: 'mrn',
        dataIndex: 'mrn',
        className: s.nameColumnWrapper,
        render: (text) => {
            return (
                <Tooltip title={text} className={s.nameColumn}>
                    <div>{text}</div>
                </Tooltip>
            );
        },
    },
    {
        title: SpecimentIdColumntTitle,
        key: 'specimen_identifier',
        dataIndex: 'specimen_identifier',
        className: s.specimenColumnWrapper,
        render: (text) => {
            return <span>{text}</span>;
        },
    },
    {
        title: 'Collection date',
        key: 'specimen_collected',
        dataIndex: 'specimen_collected',
        className: s.collectedColumnWrapper,
        render: (text) => {
            return <span>{formatHumanDateTime(text)}</span>;
        },
    },
];

const stepOnePrintedColumns = [
    {
        title: 'Flags',
        key: 'flag',
        dataIndex: 'flag',
        children: [
            {
                title: 'OK',
                key: 'ok',
                dataIndex: 'ok',
                width: 60,
            },
            {
                title: 'QNS',
                key: 'qns',
                dataIndex: 'qns',
                width: 60,
            },
            {
                title: 'Exl..',
                key: 'excluded',
                dataIndex: 'excluded',
                width: 60,
            },
            {
                title: 'NA',
                key: 'na',
                dataIndex: 'na',
                width: 60,
            },
        ],
    },
    {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
    },
];

// TODO: Probably change interface to object and reuse it in getStepOneEditableColumns
export function getStepOneColumns(
    stepOneDataReload: TableStepOneProps['stepOneDataReload'],
    flagFilters: StepOneFlagFilter[],
    removeRecordError: SetActions<string>['remove'],
    sessionStatus: LabSession['status'],
): ColumnsType<StepOneTableData> {
    return [
        ...stepOneBaseColumns,
        ...getStepOneEditableColumns(
            stepOneDataReload,
            flagFilters,
            removeRecordError,
            sessionStatus,
        ),
    ];
}

export function getStepOneEditableColumns(
    stepOneDataReload: TableStepOneProps['stepOneDataReload'],
    flagFilters: StepOneFlagFilter[],
    removeRecordError: SetActions<string>['remove'],
    sessionStatus: LabSession['status'],
): ColumnsType<StepOneTableData> {
    return [
        {
            title: 'Flags',
            key: 'flag',
            dataIndex: 'flag',
            filters: stepOneFlagsFilters as any,
            width: '240px',
            filteredValue: flagFilters,
            render: (_, record) => (
                <FlagsCellStepOne
                    record={record}
                    reload={stepOneDataReload}
                    removeRecordError={removeRecordError}
                    sessionStatus={sessionStatus}
                />
            ),
            className: s.flagCell,
        },
        {
            title: 'Notes',
            key: 'notes',
            dataIndex: 'notes',
            width: '260px',
            render: (_, record) => {
                return (
                    <NotesCell
                        record={record}
                        reload={stepOneDataReload}
                        sessionStatus={sessionStatus}
                    />
                );
            },
        },
    ];
}

export function getStepOnePrintedColumns(): ColumnsType<StepOneTableData> {
    return [
        {
            title: '#',
            key: 'number',
            dataIndex: 'number',
            width: 26,
            render: (_text: string, _record: any, index: number) => index + 1,
        },
        ...stepOneBaseColumns,
        ...stepOnePrintedColumns,
    ];
}
